var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { HttpClient, } from '@novu/shared';
export class ApiService {
    constructor(backendUrl) {
        this.backendUrl = backendUrl;
        this.isAuthenticated = false;
        this.httpClient = new HttpClient(backendUrl);
    }
    setAuthorizationToken(token) {
        this.httpClient.setAuthorizationToken(token);
        this.isAuthenticated = true;
    }
    disposeAuthorizationToken() {
        this.httpClient.disposeAuthorizationToken();
        this.isAuthenticated = false;
    }
    updateAction(messageId, executedType, status, payload) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.httpClient.post(`/widgets/messages/${messageId}/actions/${executedType}`, {
                executedType,
                status,
                payload,
            });
        });
    }
    markMessageAs(messageId, mark) {
        return __awaiter(this, void 0, void 0, function* () {
            const markPayload = mark.seen === undefined && mark.read === undefined
                ? { seen: true }
                : mark;
            return yield this.httpClient.post(`/widgets/messages/markAs`, {
                messageId,
                mark: markPayload,
            });
        });
    }
    removeMessage(messageId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.httpClient.delete(`/widgets/messages/${messageId}`, {});
        });
    }
    removeAllMessages(feedId) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = feedId
                ? `/widgets/messages?feedId=${feedId}`
                : `/widgets/messages`;
            return yield this.httpClient.delete(url);
        });
    }
    markAllMessagesAsRead(feedId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.httpClient.post(`/widgets/messages/read`, {
                feedId,
            });
        });
    }
    markAllMessagesAsSeen(feedId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.httpClient.post(`/widgets/messages/seen`, {
                feedId,
            });
        });
    }
    getNotificationsList(page, _a = {}) {
        var { payload } = _a, rest = __rest(_a, ["payload"]);
        return __awaiter(this, void 0, void 0, function* () {
            const payloadString = payload ? btoa(JSON.stringify(payload)) : undefined;
            return yield this.httpClient.getFullResponse(`/widgets/notifications/feed`, Object.assign({ page, payload: payloadString }, rest));
        });
    }
    initializeSession(appId, subscriberId, hmacHash = null) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.httpClient.post(`/widgets/session/initialize`, {
                applicationIdentifier: appId,
                subscriberId: subscriberId,
                hmacHash,
            });
        });
    }
    postUsageLog(name, payload) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.httpClient.post('/widgets/usage/log', {
                name: `[Widget] - ${name}`,
                payload,
            });
        });
    }
    getUnseenCount(query = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.httpClient.get('/widgets/notifications/unseen', query);
        });
    }
    getUnreadCount(query = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.httpClient.get('/widgets/notifications/unread', query);
        });
    }
    getTabCount(query = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.httpClient.get('/widgets/notifications/count', query);
        });
    }
    getOrganization() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpClient.get('/widgets/organization');
        });
    }
    getUserPreference() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.httpClient.get('/widgets/preferences');
        });
    }
    updateSubscriberPreference(templateId, channelType, enabled) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.httpClient.patch(`/widgets/preferences/${templateId}`, {
                channel: { type: channelType, enabled },
            });
        });
    }
}
