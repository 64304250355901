export var JobTopicNameEnum;
(function (JobTopicNameEnum) {
    JobTopicNameEnum["INBOUND_PARSE_MAIL"] = "inbound-parse-mail";
    JobTopicNameEnum["METRICS"] = "metric";
    JobTopicNameEnum["STANDARD"] = "standard";
    JobTopicNameEnum["WEB_SOCKETS"] = "ws_socket_queue";
    JobTopicNameEnum["WORKFLOW"] = "trigger-handler";
})(JobTopicNameEnum || (JobTopicNameEnum = {}));
export var ObservabilityBackgroundTransactionEnum;
(function (ObservabilityBackgroundTransactionEnum) {
    ObservabilityBackgroundTransactionEnum["JOB_PROCESSING_QUEUE"] = "job-processing-queue";
    ObservabilityBackgroundTransactionEnum["TRIGGER_HANDLER_QUEUE"] = "trigger-handler-queue";
})(ObservabilityBackgroundTransactionEnum || (ObservabilityBackgroundTransactionEnum = {}));
